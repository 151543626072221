<template>
	<div class="mainTem" :style="{'height':$store.state.frameConHeight1+'px','overflow':'auto'}">
		<el-form ref="form" inline :disabled="hasOnlyView" :rules="formRules" :model="form" label-width="110px"
			style="min-width: 1100px;width: 100%;" v-loading="loading">
			<el-card>
				<div slot="header">
					<!-- form.status:{{form.status}} -->
					<h3>{{$t('i18nn_d7aef993e73ebf60')}}</h3>
				</div>
				<div>

					<el-form-item :label="$t('i18nn_b4296642bcc9f428')" required>
						<whNoSelect ref="whNoSelect" @changeData="changWhNo" size="" :width="200"></whNoSelect>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_3e764f7ae7f5e850')">
						<el-input size="" clearable v-model="form.tracking" maxlength=""></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"  :maxlength="1000" show-word-limit  style="width: 500px"></el-input>
					</el-form-item>
				</div>
			</el-card>

			<el-card>
				<div slot="header" class="">
					<h3>{{$t('i18nn_e6bdd31025bb4f9f')}}</h3>
				</div>
				<div>
					<div style="margin-bottom: 10px;">
						<el-button type="primary" size="small" icon="el-icon-plus"
							@click="addRow">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
						<el-button type="danger" size="small" icon="el-icon-delete"
							@click="clearRow">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
					</div>

					<div>

						<el-table border :data="form.recordList" size="small" style=" width: 100%;">
							<el-table-column type="index" fixed="left" width="50" align="center"
								:label="$t('Storage.tableColumn.no')"></el-table-column>

							<!-- <el-table-column prop="tracking" :label="$t('i18nn_3e764f7ae7f5e850')" width="300">
								<template slot-scope="scope">
									<el-input size="mini" v-model="scope.row.tracking" maxlength=""></el-input>
								</template>
							</el-table-column> -->

							<el-table-column prop="goodsSku" label="SKU" label-class-name="red-required-table">
								<template slot-scope="scope">
									{{scope.row.goodsSku}}
								</template>
							</el-table-column>
							<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')" 
								label-class-name="red-required-table">
								<template slot-scope="scope">
									<el-input-number size="mini" v-model="scope.row.quantity" maxlength=""></el-input-number>
								</template>
							</el-table-column>

							<el-table-column :label="$t('Storage.tableColumn.operation')" min-width="150" fixed="right">
								<template slot-scope="scope">
									<div>
										<el-button @click="recordList($event, scope.row,scope.$index)" type="danger" size="mini"
											icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-card>

			<el-card>
				<div slot="header">
					<h3>{{$t('i18nn_f5d43732e3f6cf4d')}}</h3>
				</div>
				<div v-loading="loadingUploadFile">
					<!-- <el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop=""> -->
					<el-button type="primary" size="small" icon="el-icon-paperclip"
						@click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>

					<ul>
						<li v-for="(item,index) in form.attachments" :key="index">
							<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
								:src="item.url" :preview-src-list="form.attachments.map(itemPre=> { return itemPre.url})">
								<div slot="error" class="image-slot">
									<i class="el-icon-document"></i>
								</div>
							</el-image>
							<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>

							<!-- <el-button @click="delFile($event, item)" type="text" size="mini" icon="el-icon-delete"
								style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
							
							<el-button @click="delFileAction($event, item,index)" type="text" size="mini"
								icon="el-icon-delete" style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
							<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
								style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								
						</li>
					</ul>
					<!-- </el-form-item> -->
				</div>
			</el-card>

		</el-form>
		<div class="submit-footer" v-if="!hasOnlyView">
			<!-- <div class="submit-footer" v-if="hasEdit"> -->
			<el-button type="warning" icon="el-icon-tickets" @click="submitForm('form')"
				style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			<!-- </div> -->
			<!-- <div class="submit-footer" v-else>
				<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
			</div> -->
		</div>
		<!-- 选择SKU -->
		<!-- <dialogWSkuInventories :openTime="skuInvOpenTime" :whNo="form.whNo" @SelChange="selSkuInv"></dialogWSkuInventories> -->
		<dialogWSku :openTime="skuInvOpenTime" @sure="sureSel"></dialogWSku>

		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'ExchangeReturn/'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import dialogWSkuInventories from '@/components/StorageCenter/WSkuProduct/dialogWSkuInventories.vue';
	import dialogWSku from '@/components/StorageCenter/WSkuProduct/dialogWSku.vue';
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	export default {

		components: {
			whNoSelect,
			// dialogWSkuInventories
			dialogWSku,
			dialogFileUpload
			// HyElWhUpLoad,
			// HyQuillEditor
		},
		data() {
			return {

				dialogFormStatus: 0, //0-新增，1-修改

				loading: false,

				loading_det: false,
				//是否可编辑
				hasEdit: false,
				//是否仅预览
				hasOnlyView: false,

				skuInvOpenTime: '',
				//附件
				FileUploadOpenTime: '',
				
				loadingUploadFile: false,

				form: {
					// id: '', //"数据ID",
					tracking:'',
					whNo: '',
					recordList: [],
					attachments: [],
					remark:""
				},

				formRules: {

				},
				// loading_load: false,

				selectOption: {
					wh_car_tms_type: []
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			this.initData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_car_tms_type'],
				(data)=>{
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
			});

		},
		methods: {
			initData() {
				// if (this.$route.query.id) {
				// 	//编辑
				// 	this.hasEdit = true;
				// 	this.getPageDetData(this.$route.query.id);
				// } else {
				//新增
				// this.hasEdit = false;
				// }
				if (this.$route.query.id) {
					//编辑
					this.hasEdit = true;
					this.getBaseData(this.$route.query.id);
				} else {
					//新增
					this.hasEdit = false;
				}
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				this.form.recordList = [];
			},

			// 新建-表格
			addRow() {
				// this.form.RecordDtoList.push({
				// 	goodsSku: "",
				// 	sendAcount: undefined,
				// 	sendCTN: undefined,
				// 	ctnPCS: undefined,
				// 	shipmentCode: "",
				// 	markNo: "",
				// });
				// this.skuInvWhNo = this.form.whNo;
				// if(!this.form.whNo){
				// 	this.$message.warning(this.$t('FormMsg.Select_long_wh'))
				// 	return;
				// }
				this.skuInvOpenTime = new Date().getTime();
			},
			// selSkuInv(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.RecordDtoList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.RecordDtoList.push({
			// 				goodsSku: item.goodsSku,
			// 				tracking: undefined,
			// 				quantity: undefined,
			// 			});
			// 		}
			// 	})
			// },
			sureSel(val) {
				val.forEach(item => {
					// let hasSku = this.form.RecordDtoList.some(item2=> item.goodsSku == item2.goodsSku);
					//是否已经存在此SKU
					// if(!hasSku){//不存在
					this.form.recordList.push({
						goodsSku: item.goodsSku,
						// tracking: undefined,
						quantity: undefined,
					});
					// }
				});
			},

			clearRow() {
				this.form.recordList = [];
			},

			//删除-表格
			recordList(event, row, index) {
				if(row.id){//后端删除
					this.loading = true;
					this.$http
					  .delete(this.$urlConfig.WhExchangeReturnDel + '/' + row.id, {})
					  .then(({ data }) => {
					    this.loading = false;
					    if (200 == data.code) {
								this.form.recordList.splice(index, 1);
								this.$forceUpdate();
								this.$message.success(this.$t('i18nn_de017dafc266aa03'));
					    } else {
								this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
					    }
					  })
					  .catch(error => {
					    console.log(error);
							this.loading = false;
							this.$message.warning(data.msg ? data.msg : '删除失败!');
					  });
				} else {
					this.form.recordList.splice(index, 1);
				}
			},

			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						// type: "90"
					}
				});
				if(!this.form.attachments){
					this.form.attachments = [];
				}
				this.form.attachments = this.form.attachments.concat(fileList);
				console.log('attachments', this.form.attachments);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.attachments.splice(index, 1);
				this.$forceUpdate();
			},
			//后端删除
			delFileAction(event,row,index) {
				event.stopPropagation();
			  this.loadingUploadFile = true;
			  this.$http
			    .delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
			    .then(({ data }) => {
			      this.loadingUploadFile = false;
			      if (200 == data.code) {
							this.form.attachments.splice(index, 1);
							this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			      } else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
			      }
			    })
			    .catch(error => {
			      console.log(error);
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
			    });
			},
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			getBaseData(id) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(this.$urlConfig.WhExchangeReturnDet+"/"+id, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						// offset: 0, //当前页
						// limit: 10, //当前页显示数目
						// id: id

					})
					.then(({
						data
					}) => {
						// console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							
							this.form = data.data;
							// this.form.RecordDtoList = this.form.recordList;
							// this.form.recordList = null;
							// let singleData = data.rows[0];
							// //表格显示数据
							// this.form.id = singleData.id;
							// this.form.whNo = singleData.whNo;
							// this.form.attachments = singleData.attachments;

							this.$nextTick(() => {
								this.$refs.whNoSelect.init(this.form.whNo);
							});
							// this.getPageDetData(id);
						}

					})
					.catch(error => {
						console.log(error);
						// console.log('分页，请求失败');
						this.loading = false;
					});
			},
			//明细
			//请求分页数据
			// getPageDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.get(this.$urlConfig.WhExchangeReturnRecDet + '/' + id + '/record', {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log('分页，请求成功');
			// 			console.log(data);
			// 			this.loading = false;
			// 			//表格显示数据
			// 			this.form.RecordDtoList = data.rows;

			// 			// this.$nextTick(() => {
			// 			// 	this.$refs.whNoSelect.init(this.form.whNo);
			// 			// })

			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			// console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },

			//提交信息
			submitForm(formName) {
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.submitUpload();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });

				this.$refs[formName].validate(valid => {
					if (valid) {
						// let formData = Object.assign({}, this.form);
						
						// console.log(formData.id);

						// if (!formData.id) {
						// formData.id = null;
						// formData.userId = this.UserInfo.id;
						
						// if(!this.form.id){
						// 	let formData = this.form.recordList;
						// 	formData.forEach(item => {
						// 		item.whNo = this.form.whNo;
						// 	});
						// 	this.postData(this.$urlConfig.WhExchangeReturnExcSave, formData, () => {
						// 		console.log('submitSuccess');
						// 		this.$emit('submitSuccess');
						// 	});
						// } else {
						// 	// let formData = this.form.RecordDtoList;
						// 	// formData.forEach(item => {
						// 	// 	item.whNo = this.form.whNo;
						// 	// });
						// 	let formData = Object.assign({}, this.form);
						// 	formData.recordList.forEach(item => {
						// 		item.whNo = this.form.whNo;
						// 	});
							
						// 	this.postData(this.$urlConfig.WhExchangeReturnUpdate, formData, () => {
						// 		console.log('submitSuccess');
						// 		this.$emit('submitSuccess');
						// 	});
						// }
						
						let formData = Object.assign({}, this.form);
						// formData.recordList.forEach(item => {
						// 	item.whNo = this.form.whNo;
						// });
						
						this.postData(this.$urlConfig.WhExchangeReturnUpdate, formData, () => {
							console.log('submitSuccess');
							this.$emit('submitSuccess');
						});

						// this.postData(this.$urlConfig.WhExchangeReturnExcSave, formData, () => {
						// 	console.log('submitSuccess');
						// 	this.$emit('submitSuccess');
						// });
						// } else {
						// 	// formData.userId = this.UserInfo.id;
						// 	this.postData(this.$urlConfig.WhExchangeReturnExcSave, formData, () => {
						// 		this.$emit('submitSuccess');
						// 	});
						// }
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				this.$nextTick(() => {
					if (this.$refs[formName]) {
						this.$refs[formName].resetFields();
						// if ('form' === formName) {
						// 	this[formName]['packRecordList'] = [];
						// }
					} else {
						console.log('this.$refs[formName]', this.$refs[formName]);
					}
				});
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.initData();
				// 		this.$refs.HyElWhUpLoad.clearFileList();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });
				// this.fileList = [];
			},

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// this.getPageData();

						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>