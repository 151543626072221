export const columns_config = () => {
	return [{
			name: "柜号/跟踪号",
			nameEn:"container/Trucking No.",
			data: 'packingNo',
			// editor: 'select',
			// selectOptions: []
			type: 'text',
			// type: 'dropdown',
			// source: []
			width: 200,
		},
		{
			name: "装箱类型",
			nameEn:"packing type",
			data: 'packageTypeName',
			// type: 'text',
			type: 'dropdown',
			source: []
		},
		{
			name: "柜型",
			nameEn:"container size",
			data: 'containerSizeName',
			// type: 'text',
			type: 'dropdown',
			source: []
		},
		{
			name: "SKU",
			nameEn:"",
			data: 'goodsSku',
			type: 'text',
		},

		{
			name: "总件数",
			nameEn:"PCS",
			data: 'packCount',
			type: 'numeric',
		},
		{
			name: "预计到仓日期",
			nameEn:"arrive Date",
			data: 'arriveWhDate',
			// type: 'text',
			type: 'date', 
			dateFormat: 'YYYY-MM-DD'
		},
		{
			name: "托盘数",
			nameEn:"pallet count",
			data: 'totalPallet',
			type: 'numeric',
		},
		{
			name: "箱数",
			nameEn:"CTN",
			data: 'totalCTN',
			type: 'numeric',
		},
		{
			name: "每箱件数",
			nameEn:"PCS/CTN",
			data: 'ctnPCS',
			// editor: 'select',
			// selectOptions: [],
			type: 'numeric'
			// type: 'dropdown',
			// source: []
		},
		{
			name: "外箱规格单位",
			nameEn:"CTN Unit",
			data: 'ctnSizeUnitName',
			// type: 'text'
			type: 'dropdown',
			source: []
		},
		{
			name: "外箱毛重",
			nameEn:"CTN/Weight",
			data: 'ctnWeight',
			type: 'numeric',
		},

		{
			name: "外箱尺寸*长",
			nameEn:"CTN/L",
			data: 'ctnLength',
			type: 'numeric',
		},
		{
			name: "外箱尺寸*宽",
			nameEn:"CTN/W",
			data: 'ctnWidth',
			type: 'numeric',
		},
		{
			name: "外箱尺寸*高",
			nameEn:"CTN/H",
			data: 'ctnHeight',
			type: 'numeric',
		},
		// {
		// 	name: "备注",
		// 	nameEn:"Remark",
		// 	data: 'remark',
		// 	type: 'text'
		// },
		{
			name: "ID",
			data: 'id',
			type: 'text',
			readOnly: true
		},
	];
}

export const nestedHeadersTop_config = () => {
	return [
		[{ label: '入库信息', colspan: 7 }, { label: '外箱规格(当一箱一件时，外箱规格可不填)', colspan: 7 }]
	];
}