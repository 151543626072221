import { render, staticRenderFns } from "./ExchangeReturnSingleAdd.vue?vue&type=template&id=7570bc32&scoped=true&"
import script from "./ExchangeReturnSingleAdd.vue?vue&type=script&lang=js&"
export * from "./ExchangeReturnSingleAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7570bc32",
  null
  
)

export default component.exports